<template>
    <section class="menu-subcategory">
        <PrismicRichText :field="slice.primary.title" class="title" />
        <ul>
            <li
                v-for="(item, index) in slice.items.filter((item) => isFilledKeyText(item.linkText) && isFilledKeyText(item.link))"
                :key="`menu-item${index}`"
            >
                <ServerSideNavigationPrismicLink :field="item.link" class="link">
                    {{ item.linkText }}
                </ServerSideNavigationPrismicLink>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {MegamenuSubcategorySlice} from '@prismicio-types';
    import {useTypeGuards} from '@navbar-unlogged/composables';
    import ServerSideNavigationPrismicLink from '@navbar-unlogged/components/ServerSideNavigationPrismicLink.vue';

    defineProps(getSliceComponentProps<MegamenuSubcategorySlice>());

    const {isFilledKeyText} = useTypeGuards();
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .menu-subcategory {
        font-family: var(--joy-font-family);
        text-align: left;

        .title > :deep(h3) {
            font-size: var(--joy-font-size-primary-600);
            font-weight: var(--joy-font-weight-bold);

            @include mq.screen_md {
                margin-top: 12px;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            font-size: var(--joy-font-size-primary-400);
            margin-top: 32px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 24px;

            @include mq.screen_md {
                margin-top: 8px;
                gap: 0;

                li {
                    border-bottom: solid 1px var(--joy-color-neutral-30);
                    padding: 24px 0px;

                    &:last-child {
                        border-bottom: none;
                        margin-bottom: 6px;
                    }
                }
            }

            a {
                text-decoration: none;
                color: var(--joy-color-neutral-50);
                position: relative;

                &:after {
                    background: none repeat scroll 0 0 transparent;
                    bottom: -5px;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 50%;
                    position: absolute;
                    background: var(--joy-color-neutral-50);
                    transition: width var(--joy-transition-duration-default) var(--joy-transition-timing-function),
                        left var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                    width: 0;
                }

                &:hover:after {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
</style>
